<template>
  <div class="execution">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <!-- <basic-container> -->
    <div class="page-wrap">
      <chil-menu />
      <div class="page-main">
        <avue-crud
          ref="crud"
          :page.sync="page"
          :data="tableData"
          :table-loading="tableLoading"
          :option="tableOption"
          @on-load="getList"
          @row-update="handleUpdate"
          @row-save="handleSave"
          @search-change="searchChange"
          :before-open="beforeOpen"
          @size-change="sizeChange"
          @current-change="currentChange"
          @row-del="rowDel"
        >
          <template slot="menuLeft">
            <el-button
              class="filter-item"
              type="primary"
              size="small"
              icon="el-icon-refresh-left"
              @click="handleRefreshCache"
              >缓存
            </el-button>
          </template>
          <template slot-scope="scope" slot="menu">
            <el-button
              type="text"
              size="small"
              icon="el-icon-menu"
              @click="handleItem(scope.row, scope.index)"
              >字典项
            </el-button>
          </template>
        </avue-crud>
      </div>
    </div>

    <!-- </basic-container> -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      title="字典项管理"
      width="90%"
      @close="dictItemVisible"
    >
      <avue-crud
        ref="crudItem"
        :page.sync="itemPage"
        :data="tableDictItemData"
        v-model="form"
        :before-open="handleBeforeOpen"
        :option="tableDictItemOption"
        @size-change="itemSizeChange"
        @current-change="itemCurrentChange"
        @row-update="handleItemUpdate"
        @row-save="handleItemSave"
        @row-del="rowItemDel"
      >
      </avue-crud>
    </el-dialog>
  </div>
</template>

<script>
import {
  addItemObj,
  addObj,
  delItemObj,
  delObj,
  fetchItemList,
  fetchList,
  putItemObj,
  putObj,
  refreshCache,
  systemFlag,
} from "@/api/dict";
import { tableDictItemOption, tableOption } from "./dict";
import { mapGetters } from "vuex";
import NavMenu from "../../setting/navMenu.vue";
import Jump from "../../../components/jump/index.vue";
import ChilMenu from "../../setting/chilMenu.vue";
export default {
  name: "Dict",
  components: { ChilMenu, NavMenu, Jump },
  // mixins: [avueHeight],
  data() {
    return {
      jumpList: [{ name: "首页" }, { name: "权限管理" }, { name: "字典管理" }],
      activeUrl: "/setting/system",
      searchForm: {},
      form: {
        dictType: undefined,
        dictId: undefined,
      },
      dictType: undefined,
      dictId: undefined,
      dialogFormVisible: false,
      tableData: [],
      tableDictItemData: [],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      itemPage: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
      },
      tableLoading: false,
      tableOption: tableOption,
      tableDictItemOption: tableDictItemOption,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permissions.sys_dict_add, false),
        delBtn: this.vaildData(this.permissions.sys_dict_del, false),
        editBtn: this.vaildData(this.permissions.sys_dict_edit, false),
      };
    },
  },
  mounted() {
    /*this.$nextTick(() => {
      this.reserHeight();
      // console.log('999')
    });*/
  },
  created() {
    this.getSystemFlag();
  },
  methods: {
    async getSystemFlag() {
      const res = await systemFlag();
      this.findObject(this.tableOption.column, "system").dicData = res.data;
    },
    //======字典表格相关=====
    getList(page, params) {
      this.tableLoading = true;
      fetchList(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
            descs: "create_time",
          },
          params,
          this.searchForm
        )
      ).then((response) => {
        console.log(response);
        this.tableData = response.data.records;
        this.page.total = response.data.total;
        this.tableLoading = false;
      });
    },
    rowDel: function (row) {
      console.log(row);
      this.$confirm(
        '是否确认删除数据类型为"' + row.dictType + '"的数据项?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(function () {
          return delObj(row);
        })
        .then(() => {
          this.getList(this.page);
          this.$message.success("删除成功");
        })
        .catch(function () {});
    },
    handleUpdate: function (row, index, done) {
      putObj(row).then(() => {
        this.$message.success("修改成功");
        this.getList(this.page);
        done();
      });
    },
    handleSave: function (row, done) {
      addObj(row).then(() => {
        this.$message.success("添加成功");
        this.getList(this.page);
        done();
      });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    beforeOpen(show, type) {
      console.log("beforeOpen", type);
      window.boxType = type;
      show();
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    //======字典项表格相关=====
    dictItemVisible: function () {
      this.dialogFormVisible = false;
      this.itemPage.currentPage = 1;
    },
    refresh() {
      fetchItemList(
        Object.assign(
          {
            current: this.itemPage.currentPage,
            size: this.itemPage.pageSize,
          },
          { dictId: this.dictId }
        )
      ).then((response) => {
        this.tableDictItemData = response.data.records;
        this.itemPage.total = response.data.total;
      });
    },

    handleItem: function (row) {
      this.dictId = row.id;
      this.dictType = row.type;
      this.getDictItemList();
    },
    getDictItemList() {
      this.dialogFormVisible = true;
      fetchItemList(
        Object.assign(
          {
            current: this.itemPage.currentPage,
            size: this.itemPage.pageSize,
          },
          { dictId: this.dictId }
        )
      ).then((response) => {
        console.log(response);
        this.tableDictItemData = response.data.records;
        this.itemPage.total = response.data.total;
      });
    },
    handleBeforeOpen(done) {
      this.form.type = this.dictType;
      this.form.dictId = this.dictId;
      done();
    },
    handleItemSave: function (row, done) {
      addItemObj(row).then(() => {
        this.$message.success("添加成功");
        this.getDictItemList();
        done();
      });
    },
    handleItemUpdate: function (row, index, done) {
      putItemObj(row).then(() => {
        this.$message.success("修改成功");
        this.getDictItemList();
        done();
      });
    },
    itemSizeChange(pageSize) {
      this.itemPage.pageSize = pageSize;
      this.getDictItemList();
    },
    itemCurrentChange(current) {
      this.itemPage.currentPage = current;
      this.getDictItemList();
    },
    rowItemDel: function (row) {
      this.$confirm('是否确认删除数据为"' + row.label + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delItemObj(row.id);
        })
        .then(() => {
          this.getDictItemList();
          this.$message.success("删除成功");
        })
        .catch(function () {});
    },
    handleRefreshCache: function () {
      refreshCache()
        .then(() => {
          this.$message.success("同步成功");
        })
        .catch(function () {});
    },
  },
};
</script>
<style lang="scss">
.page-wrap {
  display: flex;
  .page-main {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.execution {
  .el-form-item__content .el-input {
    width: auto;
  }
  .user-wrap {
    display: flex;
    .user-tree {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
  }
  .user {
    height: 100%;

    &__tree {
      padding-top: 3px;
      padding-right: 20px;
    }

    &__main {
      .el-card__body {
        padding-top: 0;
      }
    }
  }
  .el-tree {
    padding-top: 15px;
    height: calc(100vh - 300px);
    background: #0c265a;
  }
  .el-card {
    background-color: transparent;
    border: none;
  }
  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 100%;
  }

  // button {
  //   height: 36px;
  //   background: linear-gradient(0deg, #1f79ff, #23b4ff);
  //   border-radius: 2px;
  //   line-height: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-card {
  background-color: transparent;
  border: none;
}
::v-deep .avue-crud {
  background: transparent;
}
::v-deep .avue-crud__menu {
  background: transparent;
  min-height: 0;
}
::v-deep .avue-crud .el-table th {
  background: rgb(17, 50, 117);
  color: rgb(163, 214, 255);
  border: none;
}
::v-deep .el-table tr {
  color: #ffffff;
  background-color: #081e49;
}
::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background-color: #0c265a;
  border-bottom: 1px solid #1d3b6a;
  border-top: 1px solid #1d3b6a;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: 0px;
}
::v-deep .el-table th.el-table__cell {
  color: #a3d6ff;
  background-color: #113275;
}
::v-deep .el-table--border::after,
::v-deep .el-table--group::after,
::v-deep .el-table::before,
::v-deep .el-table__fixed-right::before,
::v-deep .el-table__fixed::before {
  background-color: #1d3b6a;
}
::v-deep .el-table--border,
::v-deep .el-table--group {
  border: none;
  margin-top: 20px;
}
::v-deep .el-table__body tr.hover-row.current-row > td.el-table__cell,
::v-deep
  .el-table__body
  tr.hover-row.el-table__row--striped.current-row
  > td.el-table__cell,
::v-deep
  .el-table__body
  tr.hover-row.el-table__row--striped
  > td.el-table__cell,
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #113275 !important;
}
::v-deep .el-table--border .el-table__cell,
::v-deep
  .el-table__body-wrapper
  .el-table--border.is-scrolling-left
  ~ .el-table__fixed {
  border-right: none;
}
::v-deep .el-pagination__total {
  color: #a3d6ff;
}
::v-deep .el-pagination__jump {
  color: #a3d6ff;
}
::v-deep .el-table__empty-block {
  background: #0c265a;
}
::v-deep .avue-empty__desc {
  color: #a3d6ff;
}
::v-deep .avue-empty__image img {
  display: none;
}
::v-deep .avue-empty__image {
  height: 0px !important;
}
::v-deep .el-dialog__header {
  background: url("~@/assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
::v-deep .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
::v-deep .el-dialog__body {
  background: #0c265a;
}
::v-deep .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
::v-deep .el-button--default {
  background-color: #909399;
}
::v-deep .el-dialog__footer {
  background: #0c265a;
  border: none !important;
  border-top: 1px solid #326394;
}
::v-deep .el-collapse-item__wrap {
  background: transparent;
}
::v-deep .avue-group .el-collapse,
::v-deep .avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
::v-deep .avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
::v-deep .el-form-item__content .el-input {
  width: auto;
}
::v-deep .avue-group .el-form-item__content {
  display: flex;
}
::v-deep .el-button {
  color: #fff;
  border: none;
}
::v-deep .el-form-item__content button {
  height: auto;
}
::v-deep .el-form-item__label,
::v-deep .el-radio__label {
  color: #a3d6ff;
}
</style>
